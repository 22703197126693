$(function() {


    // --------------------------------------------------------------------------
	// Phone
	// --------------------------------------------------------------------------

	$('input[type=tel]').inputmask({"mask": "+7 (999) 999-99-99", showMaskOnFocus: true, showMaskOnHover: false });

	// --------------------------------------------------------------------------
	// Validate
	// --------------------------------------------------------------------------

	$.validator.addMethod("regexp", function (value, element) {
	    return this.optional(element) || /^\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(value);
	});

	var validateErrorPlacement = function(error, element) {
		error.addClass('page__form-validate');
		error.appendTo(element.parent());
	}

	var validateHighlight = function(element) {
		$(element).addClass("is-error").removeClass('is-success').parent().find('.page__form-validate').remove();
	}
	var validateUnhighlight = function(element) {
		$(element).addClass('is-success').removeClass("is-error").parent().find('.page__form-validate').remove();
	}

	$('.js-validate').each(function (index, value) { 

		$(this).validate({
			errorElement: "span",
			ignore: ":disabled,:hidden",
			highlight: validateHighlight,
    		unhighlight: validateUnhighlight,
			rules: {
				firstname: {
	                required: true,
				},
				tel: {
	                required: true,
	                regexp: true
				},
				email: {
					required: true,
      				email: true
				},
				message: {
					required: true
				}

			},
			messages: {
				firstname: 'Вы не ввели имя',
				tel: 'Вы не ввели номер телефона',
				email: 'Вы не ввели email',
				message: 'Вы не ввели комментарий'
			},
			errorPlacement: validateErrorPlacement,
			submitHandler: function(form) {

				


				var formValidate = $(form);
				var formData = new FormData(form);


				$.ajax({
					url: 'phpmailer/send.php',
					type: 'POST',
					contentType: false,
					processData: false, 
					data: formData,
					success: function(form){

						formValidate.trigger('reset');

						$('.page__form').addClass('is-success');
						
						setTimeout(function(){
							$('.page__form').removeClass('is-success');
						}, 5000);

					},
					error: function(form) {
						
					}
				});

			}
		});

	});


	// --------------------------------------------------------------------------
	// Loaded
	// --------------------------------------------------------------------------

	$('html').addClass('is-loaded');




});


